.register{
  width: 100%; 
  height: 200px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  background-color: var(--tg-theme-bg-color);
  color: var(--tg-theme-color);
  align-items: center;
  font-size: 1.4rem;
 }
 
 .reginput {
   width: 350px;
   height: 28px;
   padding: 5px;
   margin-top: 10px;
   margin-left: 5px;
   font-size: 1.1rem;
 }
 
 .regbutton_box{
  display: flex;
  flex-direction: row;
 }
 
 .reginput:active,
 .reginput:focus,
 .reginput:hover{
   outline: none;
   border-bottom-color:  #6A679E;
 }