:root {
  --primary-color: #e0c521;
}

.button {
  background: var(--tg-theme-button-color);
  padding: 0.3rem 0.3rem;
  font-size: 1.0rem;
  text-align: center;
  border: 0;
  outline: none;
  border-radius: 10px;
  width: 100px;
  height: 28px;
  margin-top: 10px;
  margin-left: 5px;
  /*box-shadow: 1px -3px 52px -5px rgba(60, 50, 153, 0.77);*/
  cursor: pointer;
}

.button.cls {
  margin-top: 0;
  margin-left: 0;
  font-size: 1rem;
}
.button.add {
  background-color: var(--primary-color);
}

.button.rmv {
  background-color: tomato;
}

.button.lgn {
  width: 90px;
  background-color: var(--tg-theme-button-color);
}

.button.reg {
  width: 160px;
  background-color: var(--tg-theme-button-color);
}


.button:active {
  transform: scale(0.98);
}

.button.add:hover {
  background-color: #cab320;
}

.button.add:active {
  background-color: #ad9a1c;
}

.button.rmv:hover {
  background-color: rgb(209, 83, 61);
}
.button.rmv:active {
  background-color: rgb(185, 73, 54);
}

.button.lgn:hover {
  background-color: rgb(41, 141, 41);
}
.button.lgn:active {
  background-color: rgb(30, 93, 41);
}

.button.reg:hover {
  background-color: rgb(41, 141, 41);
}
.button.reg:active {
  background-color: rgb(30, 93, 41);
}