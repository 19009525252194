.login{
    width: 100%; 
    height: 200px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    background-color: var(--tg-theme-bg-color);
    color: var(--tg-theme-color);
    align-items: center;
    font-size: 1.4rem;
   }

.lginput{
  width: 300px;
  height: 28px;
  padding: 10px;
  margin-top: 15px;
  font-size: 1rem;
}

.lgbutton_box{
 display: flex;
 flex-direction: row;
 justify-content: space-between;
}

.lginput:active,
.lginput:focus,
.lginput:hover{
  outline: none;
  border-bottom-color:  #6A679E;
}