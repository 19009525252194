:root {
  --primary-color: #e0c521;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 150px;
  height: 250px;
  border: 2px solid var(--primary-color);
  padding: 0.5rem 0.3rem;
  margin: 13px 10px;
  position: relative;
  border-radius: 5px;
 /* border: 1px solid var(--tg-theme-button-color); */
}
.img__container {
  width: 120px;
  height: 120px;
  margin: 0 auto;
}

.img__container img{
  width: 100%;
  object-fit: cover;
}

.description {
  font-size: 0.8em;
}
.add-btn {
  width: 100%;
}

.card__badge {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  background-color: rgb(245, 154, 90);
  border-radius: 50%;
  transform: translate(15px, -15px) scale(1);
  color: #fff;
  font-weight: bold;
  text-align: center;
  border: 2px solid rgb(227, 112, 30);
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  animation: scaleanimation 1s ease infinite;
  opacity: 0.8;
}

@keyframes scaleanimation {
  to {
    transform: translate(15px, -15px) scale(1.05);
    opacity: 1;
  }
}

.card__badge-hidden {
  display: none;
}

.btn__container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}