.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
}

.input {
  width: 100%;
  padding: 10px;
  margin-top: 15px;
}

.select {
  padding: 10px;
  margin-top: 12px;
}
