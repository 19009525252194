.header {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: center;
  padding: 5px 20px;
  background:  rgb(162, 156, 179);
  color: rgb(24, 7, 70);
  justify-content: space-between;
}

.username {
  margin-left: auto;
}